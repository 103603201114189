import React from 'react'
import {Helmet} from "react-helmet"
import {Button} from 'react-bootstrap'
import {Link} from 'gatsby'
import Navbar from '../components/oneTimeComponents/NavigationBar'
import Collegamenti from '../components/basicComponents/Collegamento'
import SPIDContainer from '../components/SPIDComponents/SPIDContainer'
import './AccessoSistemaWF.css'

export default function AccessoAlSistemaWorkflow(){
    const stileButton ={
        backgroundColor:"white",
        color:"black",
        border:"1px solid black"
    }
    return(
        <div className="pageContainer">
        <Helmet>
            <title>Accesso al Sistema Workflow</title>
        </Helmet>
        <Navbar page="IT" sezione="1"/>
        <br/>
        <div>
            <h4>
                <b>Accesso al Workflow della Prevenzione e alla Rendicontazione Finanziaria</b>
            </h4>
        </div>
        <SPIDContainer page="IT" />

        <div className="Collegamenti" style={{marginTop:"5%"}}>
                                    

        <Link to="/">
        <button className="ButtonHome" style={stileButton}>
            HOME
        </button>
        </Link>

        </div>
        </div>
    )
}